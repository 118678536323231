import React, { lazy } from 'react'
import { Redirect, Route, BrowserRouter, Switch } from 'react-router-dom'
import GlobalStyle from './style/Global'
import PageLoader from './components/PageLoader'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'

const Landing = lazy(() => import('./views/Landing'))

const App: React.FC = () => {


  return (
    <BrowserRouter>
      <GlobalStyle />
      <SuspenseWithChunkError fallback={<PageLoader />}>

        <Switch>

          <Route path="/" exact>
            <Landing />
          </Route>

          <Redirect from="*" to="/" />

        </Switch>

      </SuspenseWithChunkError>

    </BrowserRouter>
  );
}

export default App;
