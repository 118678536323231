import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  	body {
    	background-color: black;
		#root > div {
			height: 100vh;
		  }
    img {
		height: auto;
		max-width: 100%;
    }
  }
`

export default GlobalStyle
