import React from 'react'
import { HelmetProvider } from 'react-helmet-async'

const Providers: React.FC = ({ children }) => {
  return (
    <HelmetProvider>
      {children}
    </HelmetProvider>
  )
}

export default Providers
